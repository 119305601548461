import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import { useInViewport } from "react-in-viewport"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import Layout from "../components/layout"
import { useState } from "react"


import "photoswipe/dist/photoswipe.css"
// import "photoswipe/dist/default-skin/default-skin.css"

import { Gallery, Item } from "react-photoswipe-gallery"
import { Link } from "react-scroll"

import "animate.css"
import Seo from "../components/seo"


const IndexPage = () => {

  const pageQuery = useStaticQuery(graphql`
      query IndexPage {
          logo: file(name: {eq: "klima-4-you-logo"} ) {
              childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)

              }
          }
          gallery: markdownRemark(frontmatter: {title: {eq: "gallery"}}) {
              id
              frontmatter {
                  gallery {
                      image {
                          childImageSharp {
                              thumbnail: fluid(maxWidth: 300, fit: CONTAIN) {
                                  src
                              }
                              original: fluid(maxWidth: 900, fit: CONTAIN) {
                                  src
                              }
                          }
                      }
                      text
                  }
              }
          }
      }
  `)

  const [selectedAC, setSelectedAC] = useState(0)
  const [selectedDescription, setSelectedDescription] = useState(0)
  const [isConsented, setConsent] = useState(false)

  const gallery = pageQuery.gallery.frontmatter.gallery
  return (
    <Layout>
      <Seo title={`Klíma 4 You! - Klímaberednezések telepítése`} />
      <div className="container">
        <div id="hero" className="section">
          <h1 className="primary center animate__animated animate__fadeIn animate__faster">Klímaszerelés, ipari hűtés és
            légtechnika profi szinten!</h1>

          <div className="link-row animate__animated animate__fadeInLeft animate__faster animate__delay-1s">


            <div className="col">
              <Link to={"descriptions"} smooth={true} offset={-250}>
                <div className="card" onClick={() => setSelectedDescription(0)}>
                  <StaticImage placeholder={"none"} src="../images/apartment.png" alt="klíma a lakásban" width={300}
                               height={230} />
                  <h3>Klíma a lakásban</h3>
                </div>
              </Link>
            </div>
            <div className="col">

              <Link to={"descriptions"} smooth={true} offset={-250}>
                <div className="card" onClick={() => setSelectedDescription(1)}>
                  <StaticImage placeholder={"none"} src="../images/house.png" alt="klíma a házban" width={300}
                               height={230} />
                  <h3>Klíma a házban</h3>
                </div>
              </Link>
            </div>
            <div className="col">

              <Link to={"descriptions"} smooth={true} offset={-250}>
                <div className="card" onClick={() => setSelectedDescription(2)}>
                  <StaticImage placeholder={"none"} src="../images/office.png" alt="klíma a irodában" width={300}
                               height={230} />
                  <h3>
                    Klíma az irodában
                  </h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div id="services" className="section services">
          <h2>
            Szolgáltatások
          </h2>
          <p>
            A klímaberedezések akkor hatékonyak, ha az életciklusukban a kellő figyelmet kapják. Mi segítünk Önnek, hogy
            a gép választásától a karbantartásig a legjobbat válasszák.
          </p>

          <ul>
            <li>
              <div className="col image">

                <StaticImage placeholder={"none"} src="../images/tanacsadas.png" alt="klíma a irodában" />
              </div>
              <div className="col description">
                <h3>Ingyenes tanácsadás</h3>
                <p>Kérje ingyenes felmérésünket és segítünk megtalálni Önnek a legjobb klímaszerelési megoldást.</p>
              </div>

            </li>
            <li>
              <div className="col image">

                <StaticImage placeholder={"none"} src="../images/szereles.png" alt="klíma a irodában" />
              </div>
              <div className="col description">
                <h3>Klíma szerelés</h3>
                <p>A professzionális telepítéshez tapasztalat szükséges, nálunk pontosan ezt fogja kapni. Klímáját
                  szakszerűen, de gyorsan és tisztán telepítjük.</p>
              </div>

            </li>
            <li>

              <div className="col image">

                <StaticImage placeholder={"none"} src="../images/karbantartas.png" alt="klíma a irodában" />
              </div>
              <div className="col description">
                <h3>Karbantartás</h3>
                <p>
                  A klímatisztítás több szempontból is nagyon fontos!
                  Ha elhanyagoljuk klímánk karbantartását, könnyen a készülék meghibásodásához vezethet. Ugyanakkor az
                  egészségügyi állapotunkra is rossz hatással lehet. A klímatisztítás elmulasztása egy idő után
                  kellemetlen, penészes szaggal jár. A klímaberendezések beltéri egységében a nedves közeg hatására
                  könnyen megtapadnak a baktériumok, gombák és a kórokozók. Ezek megbetegedést, akár krónikus
                  felső-légúti betegséget is okozhatnak. A rendszeres, évenkénti tisztítással mindez megelőzhető.</p>
              </div>
            </li>
          </ul>
        </div>

      </div>
      <div className="description-fluid">
        <div className={"container"}>


          <div id="descriptions" className="section descriptions">
            <Carousel noThumbs={true}
                      showThumbs={false}
                      showStatus={false}
                      showIndicators={false}
                      emulateTouch={true}
                      selectedItem={selectedDescription}
                      renderArrowPrev={(n, h) => <div onClick={n} className="control control-prev" style={{
                        opacity: h ? 1 : .2
                      }}></div>}
                      renderArrowNext={(p, h) => <div onClick={p} className="control control-next" style={{
                        opacity: h ? 1 : .2
                      }}></div>}

            >
              <div className="description-item">
                <div className="col">
                  <h4>Klíma a lakásban</h4>
                  <p>
                    Minden felmérés alkalmával a lakók szokásaihoz igazítva, a bútorok elhelyezésének
                    figyelembevételével
                    kell kijelölni a klíma beltéri egységének a helyét, hogy a késöbbi használat során maximálisan
                    komfortosan működtethessük.
                  </p>
                  <p>
                    Nálunk részletes tanácsokat kap, és tiszta és gyors munkára számíthat.
                  </p>

                  <Link to={"contact"} smooth={true} offset={-250}>
                    <button className={"request-offer"}>
                      Ajánlat kérése
                    </button>
                  </Link>
                </div>
                <div className="col">
                  <StaticImage src={"../images/lakas-leiras.png"} placeholder={"none"} alt={"Egy klíma a lakásban"} />
                </div>

              </div>
              <div className="description-item">
                <div className="col">
                  <h4>Klíma a Házban</h4>
                  <p>
                    Az ingyenes helyszíni felmérés során a Klíma4You csapata személyesen az Ön házának legmegfelelőbb
                    légkondicionáló megoldást kínálja!
                    Részletes tanácsokat kap, és tiszta és gyors munkára számíthat.
                  </p>

                  <Link to={"contact"} smooth={true} offset={-250}>
                    <button className={"request-offer"}>
                      Ajánlat kérése
                    </button>
                  </Link>
                </div>
                <div className="col">
                  <StaticImage src={"../images/haz-leiras.jpg"} placeholder={"none"} alt={"Egy klíma a házban"} />
                </div>
              </div>
              <div className="description-item">
                <div className="col">
                  <h4>Klíma az irodában</h4>

                  <p>
                    A nyári melegben végzett munka csökkenti a koncentrációt és a hatékonyságot. Ha ezt szeretné
                    megelőzni, mi a legkorszerűbb technológiával garantáljuk Önnek és munkatársainak, a tökéletes
                    hőmérsékletet amelyben jól érzik magukat. Klímaberendezéseinkkel a nyári hónapokban is maximális
                    komfortérzetet garantálhat dolgozóinak az irodában.
                  </p>

                  <Link to={"contact"} smooth={true} offset={-250}>
                    <button className={"request-offer"}>
                      Ajánlat kérése
                    </button>
                  </Link>
                </div>
                <div className="col">
                  <StaticImage src={"../images/iroda-leiras.jpg"} placeholder={"none"} alt={"Egy klíma az irodában"} />
                </div>
              </div>
            </Carousel>

          </div>

        </div>
      </div>

      <div className={"fluid"}>
        <StaticImage src={"../images/red-wave.png"} className={"red-wave wave"} width={300} alt={"dekorációs hullám"} />
        <StaticImage src={"../images/blue-wave.png"} className={"blue-wave wave"} width={300}
                     alt={"dekorációs hullám"} />
        <div className="container">

          <div id="ac-units" className="section ac-units">
            <h2>
              Klímaberendezések
            </h2>
            <p>Számos márka közül, Önnel közösen választjuk ki a céljainak megfelelő készüléket.</p>
            <h3>Melyik márkát válasszam?</h3>
            <div className="row">

              <div className="col ac-controller">


                <button onClick={() => {
                  setSelectedAC(0)
                }}>
                  <StaticImage className={selectedAC === 0 ? "active" : "available"} placeholder={"none"}
                               src="../images/fujitsu.png"
                               width={150} height={90} objectFit={"contain"} alt="klíma a irodában" />
                </button>


                <button onClick={() => {
                  setSelectedAC(1)
                }}>
                  <StaticImage className={selectedAC === 1 ? "active" : "available"} placeholder={"none"}
                               src="../images/fisher.png" width={150} height={90}
                               objectFit={"contain"} alt="klíma a irodában" />
                </button>
                <button onClick={() => {
                  setSelectedAC(2)
                }}>
                  <StaticImage className={selectedAC === 2 ? "active" : "available"} placeholder={"none"}
                               src="../images/panasonic.png" width={150} height={90}
                               objectFit={"contain"} alt="klíma a irodában" />
                </button>
              </div>
              <div className="col">
                <Carousel showThumbs={false}
                          showStatus={false}
                          showIndicators={false}
                          emulateTouch={false}
                          showArrows={true}
                          swipeable={false}
                          selectedItem={selectedAC}
                          axis={"horizontal"}
                >
                  <div className="unit-item">
                    <h4>Fujitsu</h4>

                    <p>
                      A piacon több tíz vagy akár száz gyártó is jelen van, de egyre többen választják a Fujitsut. Semmi
                      sem történik ok nélkül, az emberek bizalma még is töretlen a márka felé. A Fujitsu név a
                      légkondicionáló gyártásban már régóta híres és prémium termékeket gyárt elérhető áron. A
                      népszerűségének oka a kitűnő minőség, kiváló ár-érték arány, széles modell választék, gazdaságos
                      működés és a jó szerelhetőség.
                    </p>
                  </div>
                  <div className="unit-item">
                    <h4>Fisher</h4>

                    <p>
                      A tudatos vásárlóknak ajánljuk a Fisher klímákat, akik a legjobb ár-érték arányt keresik. A Fisher
                      klíma márka termékfejlesztési stratégiáját adott termékkörben mindig a lehető legtöbb extra
                      funkció, a versenyképes energiahatékonyság és az ehhez képest reális árszint kombinációja adja. Az
                      egyetlen Superbrands díjas légkondicionáló márka, ez jól mutatja hazai népszerűségét. Ára
                      középkategóriában van, a legjobb ár-érték aránnyal bír.
                    </p>
                  </div>

                  <div className="unit-item">
                    <h4>Panasonic</h4>

                    <p>
                      A Panasonic minden terméke a minőséget jelenti. Így van ez a klíma berendezések esetében is. A
                      Panasonic klíma berendezései mindig a legújabb fejlesztéseket tartalmazzák, melyek mind a kellemes
                      környezet kialakítása érdekében lettek a klíma berendezésekbe beépítve.
                    </p>
                  </div>
                </Carousel>
              </div>
            </div>

          </div>

          <div id="gallery" className="section">
            <h2>Munkáink</h2>

            {gallery.length && <>
              <Gallery zoomButton={false} fullscreenButton={false} shareButton={false} options={{}}>
                <div className="gallery-container">
                  {gallery.map((galleryItem, index) => {

                      return <Item
                        key={`gallery-${index}`}

                        content={
                        <div className="gallery-lightbox">
                          <img
                            alt={galleryItem.text}
                            src={galleryItem.image?.childImageSharp?.original?.src}
                            objectFit="contain"
                          />
                        </div>
                      }>
                        {({ ref, open }) => (
                          <button onClick={open} ref={ref}>
                            <img
                              className={"gallery-thumbnail"}
                              alt={galleryItem.text}
                              objectFit="contain"
                              src={galleryItem.image?.childImageSharp?.thumbnail?.src}
                            />
                          </button>
                        )}
                      </Item>
                    }
                  )
                  }
                </div>
              </Gallery>
            </>}


          </div>
        </div>
      </div>
      <div className="fluid contact">
        <div className="container">
          <div className="row section" id="contact">
            <div className="col contact">
              <h2>Kapcsolat</h2>
              <p>
                Elérhetőek vagyunk az alábbi formákon:
              </p>
              <p>
                <StaticImage src={"../images/email.png"} placeholder={"none"} width={20} height={20}
                             alt={"email ikon"} />
                <strong>
                  Email
                </strong>
              </p>
              <p>
                <a href="mailto:kapcsolat@klimaforyou.hu">kapcsolat@klimaforyou.hu</a>
                <StaticImage src={"../images/copy.png"} placeholder={"none"} width={20} height={20} alt={"másolás ikon"}
                             onClick={() => {
                               navigator.clipboard.writeText("kapcsolat@klimaforyou.hu")
                             }} />
              </p>

              <p>
                <StaticImage src={"../images/phone.png"} placeholder={"none"} width={20} height={20}
                             alt={"email ikon"} />
                <strong>
                  Telefon
                </strong>
              </p>
              <p>
                <a href="tel:+36307147878">+36 30 714 7878</a>
                <StaticImage src={"../images/copy.png"} placeholder={"none"} width={20} height={20} alt={"másolás ikon"}
                             onClick={() => {
                               navigator.clipboard.writeText("+36 30 714 7878")
                             }} />
              </p>

              <p>
                <StaticImage src={"../images/cim.png"} placeholder={"none"} width={20} height={20} alt={"cím ikon"} />
                <strong>
                  Levelezési cím
                </strong>
              </p>
              <p>
                8440 Herend, Vadvirág utca 17.
              </p>

              <p>
                <div className="fb-like" data-href="https://www.facebook.com/klima4youkft" data-width=""
                     data-layout="button" data-action="like" data-size="large" data-share="true"></div>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="fluid footer-blue">
        <div className={"container"}>
          <p>Adószám: 29250936219</p>
          Copyright Klíma 4 you Kft 2024. <br />
          Minden jog fenntartva.
        </div>
      </div>


    </Layout>
  )
}

export default IndexPage
